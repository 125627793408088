::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #F1F3F4;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb:hover {
    background: #5E6366;
}

::-webkit-scrollbar-thumb:active {
    background: #919699;
}